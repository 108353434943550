import React from 'react';
import s from './Checkbox.module.css';

export const Checkbox = React.memo(props => {
  const { checked, onChangeChecked, onChange, label, id, name, ...restProps } = props;

  return (
    <div>
      <input name={name} type={'checkbox'} id={id} onChange={onChangeChecked} checked={checked} className={s.input} {...restProps} />
      <label className={s.label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
});
