import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step2SeveralFlightsPage.module.css';
import { Header } from '../../components/Header/Header';
import { CPMTable } from '../../components/CPMTable/CPMTable';
import { FlightsMediaChoiceTable } from '../../components/FlightsMediaChoiceTable/FlightsMediaChoiceTable';
import { useHistory, useLocation } from 'react-router-dom';
import { StepButton } from '../../components/Common/StepButton/StepButton';
import { setCurrentPathname } from '../../../bll/app_reducer';
import { Warning } from '../../components/Common/Warning/Warning';
import { Select } from '../../components/Common/Select/Select';
import { findSelectedMediaAllFlightsWithoutRepeat } from '../../../helpers/flightTable_helpers';
import { setFlightsMediaArr, setFlightsMonth, setFlightsInputStep1DataChanged, setFlightsInputStep2DataChanged, setCPPAllMonth } from '../../../bll/flights_reducer';
import setting from '../../../assets/images/setting.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { postFlightsCharts } from '../../../bll/flights_charts_reducer';
const coeff = require('../../../assets/data/coeff.json');

export const Step2SeveralFlightsPage = React.memo(props => {
  const flightsMediaArr = useSelector(state => state.flights.flightsMediaArr);
  const flightsInputStep1DataChanged = useSelector(state => state.flights.flightsInputStep1DataChanged);
  const flightsInputStep2DataChanged = useSelector(state => state.flights.flightsInputStep2DataChanged);
  const copyCPPAllMonth = useSelector(state => state.flights.copyCPPAllMonth);
  const selectedMediaAllFlights = findSelectedMediaAllFlightsWithoutRepeat(flightsMediaArr);

  const [mediaChoiceTableWarningActive, setMediaChoiceTableWarningActive] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [CPMTAbleVisible, setCPMTAbleVisible] = useState(false);
  const setCpmForAllMonths = () => {
    setCPMTAbleVisible(prevState => !prevState);
  };

  const flightsMonth = useSelector(state => state.flights.flightsMonth);
  const onClickNextButton = () => {
    (flightsInputStep1DataChanged === true || flightsInputStep2DataChanged === true) && dispatch(postFlightsCharts('₽', 'all'));
    dispatch(setFlightsInputStep1DataChanged(false));
    dispatch(setFlightsInputStep2DataChanged(false));
    history.push('/flightsStep3');
  };
  const onClickBackButton = () => {
    history.push('/flightsStep1');
  };

  const onChangeMonth = selectItem => {
    const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
      const updatedMediaState = item.copyStartMedia.map(el => {
        return { ...el };
      });

      updatedMediaState.map(item => {
        const currentCoeff = coeff[item.media].find(i => i[selectItem])[selectItem];
        item.CPP = item.CPP * currentCoeff;
        return item;
      });

      const newItem = { ...item };
      newItem.media = updatedMediaState;
      return newItem;
    });
    dispatch(setFlightsMonth(selectItem));
    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
    dispatch(setFlightsInputStep2DataChanged(true));
  };
  const returnDefaultMediaData = () => {
    const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
      const updatedMediaState = item.defaultMediaData.map(el => {
        return { ...el };
      });
      const newItem = { ...item };
      newItem.media = updatedMediaState;
      newItem.copyStartMedia = updatedMediaState;
      return newItem;
    });
    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
    dispatch(setFlightsMonth('Июнь'));
    dispatch(setCPPAllMonth(copyCPPAllMonth));
    dispatch(setFlightsInputStep2DataChanged(true));
  };

  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);

  return (
    <>
      <Header />
      <div className={s.step2Wrapper}>
        <div className={s.wrapper}>
          <StepButton onClick={onClickBackButton} title={'Вернуться\n назад'} />
          <div className={s.mediaChoiceBlock}>
            <h1>Параметры для выбранных медиа</h1>

            <div className={s.table}>
              <FlightsMediaChoiceTable selectedMediaAllFlights={selectedMediaAllFlights} />
            </div>
            <div className={s.monthBlock}>
              <div className={s.monthSelectBorder}>
                <div className={s.monthSelectWrapperTitle}>
                  <img src={setting} alt='newProgect' style={{ width: '40px', height: '40px' }} />
                </div>
                <div className={s.monthSelectWrapper}>
                  <div>
                    Выбрать месяц для указанного <br /> CPT / CPM, система пересчитает все месяцы
                  </div>
                  <div className={s.monthSelect}>
                    <Select name={'month'} value={flightsMonth} onChange={onChangeMonth} items={['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']} location={'targetAudience'} />
                  </div>
                </div>
              </div>

              <div style={{ marginTop: '5px' }}>
                <div className={s.monthSelectBorder}>
                  <div className={s.changeCoeffWrapper} onClick={setCpmForAllMonths}>
                    <div>
                      Задать вручную CPT / CPM <br />
                      для всех месяцев
                    </div>
                    {CPMTAbleVisible ? (
                      <div>
                        <FontAwesomeIcon icon={faSortUp} size='3x' style={{ color: '#c4c4c4' }} />
                      </div>
                    ) : (
                      <div>
                        <FontAwesomeIcon icon={faSortDown} size='3x' style={{ color: '#c4c4c4' }} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={s.resetBtnWrapper}>
              <StepButton onClick={returnDefaultMediaData} title={'Сбросить\n изменения'} />
            </div>

            <StepButton onClick={onClickNextButton} title={'Следующий\n шаг'} isDisabled={false} />
          </div>
        </div>

        {/* <div className={s.monthBlock}>
          <div className={s.monthSelectBorder}>
            <div className={s.monthSelectWrapperTitle}>
              <img src={setting} alt='newProgect' style={{ width: '40px', height: '40px' }} />
            </div>
            <div className={s.monthSelectWrapper}>
              <div>
                Выбрать месяц для указанного <br /> CPT / CPM, система пересчитает все месяцы
              </div>
              <div className={s.monthSelect}>
                <Select name={'month'} value={flightsMonth} onChange={onChangeMonth} items={['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']} location={'targetAudience'} />
              </div>
            </div>
          </div>

          <div style={{ marginTop: '5px' }}>
            <div className={s.monthSelectBorder}>
              <div className={s.changeCoeffWrapper} onClick={setCpmForAllMonths}>
                <div>
                  Задать вручную CPT / CPM <br />
                  для всех месяцев
                </div>
                {CPMTAbleVisible ? (
                  <div>
                    <FontAwesomeIcon icon={faSortUp} size='3x' style={{ color: '#c4c4c4' }} />
                  </div>
                ) : (
                  <div>
                    <FontAwesomeIcon icon={faSortDown} size='3x' style={{ color: '#c4c4c4' }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div> */}
        {CPMTAbleVisible && <CPMTable selectedMediaAllFlights={selectedMediaAllFlights} />}

        <Warning text={'Данные введены не корректно!'} warningVisible={mediaChoiceTableWarningActive} setWarningVisible={setMediaChoiceTableWarningActive} />
      </div>
    </>
  );
});
