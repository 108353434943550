import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step2Page.module.css';
import { Header } from '../../components/Header/Header';
import { MediaChoiceTable } from '../../components/MediaChoice/MediaChoiceTable/MediaChoiceTable';
import { useHistory, useLocation } from 'react-router-dom';
import { StepButton } from '../../components/Common/StepButton/StepButton';
import { setAllMedia, setSelectedMedia, setMonth, setInputDataChanged, setIsMonthVisible } from '../../../bll/inputParameters_reducer';
import { setCurrentPathname } from '../../../bll/app_reducer';
import { Warning } from '../../components/Common/Warning/Warning';
import { Select } from '../../components/Common/Select/Select';
import { setMainMedia, setIsChartsUpload } from '../../../bll/charts_reducer';
const coeff = require('../../../assets/data/coeff.json');

export const Step2Page = React.memo(props => {
  const { media } = props;
  const [mediaChoiceTableWarningActive, setMediaChoiceTableWarningActive] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const someMediaSelected = media.filter(item => item.isChecked === true);
  const mediaIsSelected = useSelector(state => state.input.mediaIsSelected);
  const copySelectedMedia = useSelector(state => state.input.copySelectedMedia);
  const month = useSelector(state => state.input.month);
  const isMonthVisible = useSelector(state => state.input.isMonthVisible);
  const onClickNextButton = () => {
    history.push('/step3');
  };
  const onClickBackButton = () => {
    history.push('/step1');
  };
  const returnDefaultMediaData = () => {
    dispatch(setAllMedia(copySelectedMedia));
    dispatch(setIsMonthVisible(1));
    dispatch(setMonth('Июнь'));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
  };

  const onChangeMonth = selectItem => {
    const updatedMediaState = copySelectedMedia.map(el => {
      return { ...el };
    });

    updatedMediaState.map(item => {
      const currentCoeff = coeff[item.media].find(i => i[selectItem])[selectItem];
      item.CPP = item.CPP * currentCoeff;
      return item;
    });
    dispatch(setMonth(selectItem));
    dispatch(setAllMedia(updatedMediaState));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
  };
  useEffect(() => {
    mediaIsSelected === false && dispatch(setSelectedMedia());
  }, [dispatch, mediaIsSelected]);
  useEffect(() => {
    const warning = media.find(item => +item.coeff > 100);
    warning && setMediaChoiceTableWarningActive(true);
  }, [media]);
  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);
  return (
    <>
      <Header />
      <div className={s.step2Wrapper}>
        <StepButton onClick={onClickBackButton} title={'Вернуться назад'} />
        <div className={s.mediaChoiceBlock}>
          <h1>Выбор медиа</h1>

          <div className={s.table}>
            <MediaChoiceTable media={media} />
          </div>
          <div className={s.charts}></div>
        </div>
        <div style={{ position: 'relative' }}>
          {isMonthVisible === 1 && (
            <div className={s.monthSelectWrapper}>
              <div>
                Месяц для CPT/CPM <br /> по умолчанию:
              </div>
              <div className={s.monthSelect}>
                <Select name={'month'} value={month} onChange={onChangeMonth} items={['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']} location={'targetAudience'} />
              </div>
            </div>
          )}

          <div className={s.resetBtnWrapper}>
            <StepButton onClick={returnDefaultMediaData} title={'Сбросить изменения'} />
          </div>
          <div>
            <StepButton onClick={onClickNextButton} title={'Следующий шаг'} isDisabled={someMediaSelected && someMediaSelected.length > 1 ? false : true} />
          </div>
        </div>
        <Warning text={'Данные введены не корректно!'} warningVisible={mediaChoiceTableWarningActive} setWarningVisible={setMediaChoiceTableWarningActive} />
      </div>
    </>
  );
});
