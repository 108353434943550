import { API2 } from '../dal/api';
import { setStatus, setError } from './app_reducer';
import { setAllChartsData, setMainMedia, setContributionOfEachMedia_data, setDonutData, setOpticMediaMixGraphData, setTs_pieChart_unit_checked, setTs_pieChart_grouping_checked, setTs_contribution_chart_unit_checked, setTs_contribution_chart_grouping_checked, setTs_optic_media_mix_chart_unit_checked, setTs_optic_media_mix_chart_grouping_checked, setUnitDonut, setUnitOptic, setIsChartsUpload, setColorsArray, setColorsGroupArray } from './charts_reducer';
import { setAllChartsDataGrowth, setAllChartsDataCoverage, set_investment_coverage_graph_data, set_investment_growth_graph_data, setUnitGrowth, setUnitCoverage, setTs_investment_growth_chart_unit_checked, setTs_investment_growth_chart_grouping_checked, setTs_investment_coverage_chart_unit_checked, setTs_investment_coverage_chart_grouping_checked, setIsGrowthChartRendered, setIsCoverageChartRendered, setKnowledgeArr } from './charts_part2_reducer';
import { round, findMainMediaByBudget, setMillion, findSum, makeArrayBudget, makeArrayGroup, makeArrayBudgetPr, makeArrayBudgetGroup, makeArrayBudgetGroupPr, findName, colorsArr, colorsGroupArr, makeSumBudget, makeArrayGroupNames } from '../helpers/chart_helpers';
import { makeFilteredMedia, makeGroupsNames } from '../helpers/mediaChoiceTable_helpers';
import { findTAWithOldNames } from '../helpers/start_helpers';

const SET_TARGET_AUDIENCE = 'SET_TARGET_AUDIENCE';
const SET_GEO = 'SET_GEO';
const SET_BUDGET_COVERAGE = 'SET_BUDGET_COVERAGE';
const SET_FREQUENCY = 'SET_FREQUENCY';
const SET_PERIOD = 'SET_PERIOD';
const SET_CAMPAIGN_TYPE = 'SET_CAMPAIGN_TYPE';
const SET_MEDIA = 'SET_MEDIA';
const SET_ALL_MEDIA = 'SET_ALL_MEDIA';
const SET_TARGET_AUDIENCE_DATA = 'SET_TARGET_AUDIENCE_DATA';
const SET_COPY_SELECTED_MEDIA = 'SET_COPY_SELECTED_MEDIA';
const SET_MEDIA_IS_SELECTED = 'SET_MEDIA_IS_SELECTED';
const SET_GROUP_CHECKED_ARR = 'SET_GROUP_CHECKED_ARR';
const SET_BUDGET_FOR_NEXT_CHARTS = 'SET_BUDGET_FOR_NEXT_CHARTS';
const SET_INPUT_DATA_CHANGED = 'SET_INPUT_DATA_CHANGED';
const SET_POST_DATA = 'SET_POST_DATA';
const SET_AUD_TOTAL = 'SET_AUD_TOTAL';
const SET_AUD_TOTALCURRENTVALUE = 'SET_AUD_TOTALCURRENTVALUE';
const SET_MONTH = 'SET_MONTH';
const SET_IS_MONTH_VISIBLE = 'SET_IS_MONTH_VISIBLE';
const SET_ISRESTRICTIONS = 'SET_ISRESTRICTIONS';
const SET_ISRESTRICTIONS_MEDIA_BUDGET = 'SET_ISRESTRICTIONS_MEDIA_BUDGET';

const initialState = {
  targetAudienceData: [],
  targetAudience: '',
  geo: [
    { id: 1, name: 'NAT', isChecked: false, geo: 'NAT' },
    { id: 2, name: 'Moscow', isChecked: false, geo: 'Moscow' },
    { id: 3, name: 'Kazan', isChecked: false, geo: 'Kazan' },
  ],
  budget_coverage: [
    { id: 1, name: 'budget', value: 0, isChecked: false },
    { id: 2, name: 'coverage', value: 0, isChecked: false },
    { id: 3, name: 'different_budget', value: 0, isChecked: false },
  ],
  frequency: '',
  period: 30,
  isRestrictions: 1,
  campaignType: [
    { id: 1, name: 'Промо', isChecked: false },
    { id: 2, name: 'Имидж', isChecked: false },
    { id: 3, name: '...', isChecked: false },
  ],
  media: [],
  copySelectedMedia: [],
  mediaIsSelected: false,
  allMediaFromBack: [],
  groupCheckedArr: [],
  BUDGET_FOR_NEXT_CHARTS: '',
  inputDataChanged: false,
  postData: {},
  Aud_total: [],
  Aud_totalCurrentValue: null,
  month: 'Июнь',
  isMonthVisible: 1,
  isRestrictionsMediaBudget: 1,
};

export const inputParameters_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TARGET_AUDIENCE:
      return { ...state, targetAudience: action.targetAudience };
    case SET_GEO:
      return { ...state, geo: action.geo };
    case SET_BUDGET_COVERAGE:
      return { ...state, budget_coverage: action.budget_coverage };
    case SET_FREQUENCY:
      return { ...state, frequency: action.frequency };
    case SET_PERIOD:
      return { ...state, period: action.period };
    case SET_CAMPAIGN_TYPE:
      return { ...state, campaignType: action.campaignType };
    case SET_MEDIA:
      return { ...state, media: action.media };
    case SET_ALL_MEDIA:
      return { ...state, allMediaFromBack: action.allMediaFromBack };
    case SET_TARGET_AUDIENCE_DATA:
      return { ...state, targetAudienceData: action.targetAudienceData };
    case SET_MEDIA_IS_SELECTED:
      return { ...state, mediaIsSelected: action.mediaIsSelected };
    case SET_GROUP_CHECKED_ARR:
      return { ...state, groupCheckedArr: action.groupCheckedArr };
    case SET_COPY_SELECTED_MEDIA:
      return { ...state, copySelectedMedia: action.copySelectedMedia };
    case SET_BUDGET_FOR_NEXT_CHARTS:
      return { ...state, BUDGET_FOR_NEXT_CHARTS: action.BUDGET_FOR_NEXT_CHARTS };
    case SET_INPUT_DATA_CHANGED:
      return { ...state, inputDataChanged: action.inputDataChanged };
    case SET_POST_DATA:
      return { ...state, postData: action.postData };
    case SET_AUD_TOTAL:
      return { ...state, Aud_total: action.Aud_total };
    case SET_AUD_TOTALCURRENTVALUE:
      return { ...state, Aud_totalCurrentValue: action.Aud_totalCurrentValue };
    case SET_MONTH:
      return { ...state, month: action.month };
    case SET_IS_MONTH_VISIBLE:
      return { ...state, isMonthVisible: action.isMonthVisible };
    case SET_ISRESTRICTIONS:
      return { ...state, isRestrictions: action.isRestrictions };
    case SET_ISRESTRICTIONS_MEDIA_BUDGET:
      return { ...state, isRestrictionsMediaBudget: action.isRestrictionsMediaBudget };
    default:
      return state;
  }
};

// action creators
export const setTargetAudience = targetAudience => ({ type: SET_TARGET_AUDIENCE, targetAudience });
export const setGeo = geo => ({ type: SET_GEO, geo });
export const setBudget_Coverage = budget_coverage => ({ type: SET_BUDGET_COVERAGE, budget_coverage });
export const setFrequency = frequency => ({ type: SET_FREQUENCY, frequency });
export const setPeriod = period => ({ type: SET_PERIOD, period });
export const setCampaignType = campaignType => ({ type: SET_CAMPAIGN_TYPE, campaignType });
export const setAllMedia = media => ({ type: SET_MEDIA, media });
export const setAllMediaFromBack = allMediaFromBack => ({ type: SET_ALL_MEDIA, allMediaFromBack });
export const setMediaIsSelected = mediaIsSelected => ({ type: SET_MEDIA_IS_SELECTED, mediaIsSelected });
const setAllTargetAudienceData = targetAudienceData => ({ type: SET_TARGET_AUDIENCE_DATA, targetAudienceData });
export const setGroupCheckedArr = groupCheckedArr => ({ type: SET_GROUP_CHECKED_ARR, groupCheckedArr });
export const setCopySelectedMedia = copySelectedMedia => ({ type: SET_COPY_SELECTED_MEDIA, copySelectedMedia });
export const setBUDGET_FOR_NEXT_CHARTS = BUDGET_FOR_NEXT_CHARTS => ({ type: SET_BUDGET_FOR_NEXT_CHARTS, BUDGET_FOR_NEXT_CHARTS });
export const setInputDataChanged = inputDataChanged => ({ type: SET_INPUT_DATA_CHANGED, inputDataChanged });
export const setPostData = postData => ({ type: SET_POST_DATA, postData });
export const setAud_total = Aud_total => ({ type: SET_AUD_TOTAL, Aud_total });
export const setAud_totalCurrentValue = Aud_totalCurrentValue => ({ type: SET_AUD_TOTALCURRENTVALUE, Aud_totalCurrentValue });
export const setMonth = month => ({ type: SET_MONTH, month });
export const setIsMonthVisible = isMonthVisible => ({ type: SET_IS_MONTH_VISIBLE, isMonthVisible });
export const setIsRestrictions = isRestrictions => ({ type: SET_ISRESTRICTIONS, isRestrictions });
export const setIsRestrictionsMediaBudget = isRestrictionsMediaBudget => ({ type: SET_ISRESTRICTIONS_MEDIA_BUDGET, isRestrictionsMediaBudget });

export const setSelectedMedia = () => async (dispatch, getState) => {
  const allMediaFromBack = getState().input.allMediaFromBack;
  const targetAudience = getState().input.targetAudience;
  const campaignType = getState().input.campaignType;
  const campaignTypePOST = campaignType.find(type => type.isChecked) && campaignType.find(type => type.isChecked).name;
  const geo = getState().input.geo;
  const geoPOST = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;

  const selectedMedia = allMediaFromBack.filter(media => media.TA === targetAudience && media.Geo === geoPOST && media.type === campaignTypePOST);
  // const Aud_totalCurrentValue = Aud_total.filter(item => item.Aud === targetAudience && item.Geo === geoPOST)[0].total;

  const makeGroups = arr => {
    let groups = [];
    arr.map(arrItem => {
      !groups.find(group => group === arrItem.group) && groups.push(arrItem.group);
      return arrItem;
    });
    const groupsArr = groups.map(media => {
      return {
        [media]: false,
      };
    });
    return groupsArr;
  };
  const groups = makeGroups(selectedMedia);

  const selectedMediaTrueShape = selectedMedia.map(media => {
    return {
      media: media.Media,
      CPP: +media.CPP / media.k,
      // CPP: (+media.CPP * 100) / Aud_totalCurrentValue,
      coeff: +media.coeff * 100,
      group: media.group,
      startBudget: 0,
      finishBudget: 999999999,
      isChecked: false,
      k: media.k,
    };
  });

  // CPP*100000/размер аудитории

  const groupsNames = makeGroupsNames(selectedMediaTrueShape);
  const filteredMedia = makeFilteredMedia(groupsNames, selectedMediaTrueShape);
  try {
    dispatch(setStatus('loading'));
    dispatch(setGroupCheckedArr(groups));
    dispatch(setAllMedia(filteredMedia));
    dispatch(setCopySelectedMedia(filteredMedia));
    // dispatch(setAud_totalCurrentValue(Aud_totalCurrentValue));

    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const setStartData = () => async dispatch => {
  try {
    dispatch(setStatus('loading'));
    let TAData = [];
    const response = await API2.get_media({ project: 'mediamix' });
    for (const data of response.data.TA) {
      TAData.push(data.TA);
    }
    dispatch(setAllTargetAudienceData(TAData));
    dispatch(setAllMediaFromBack(response.data.TRP_REACH));
    dispatch(setAud_total(response.data.Aud_total));
    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const postPart1 = (unit, grouping) => async (dispatch, getState) => {
  const unitOptic = getState().charts.unitOptic;
  const Aud_totalCurrentValue = getState().input.Aud_totalCurrentValue;
  function removePlus(str) {
    return +str.substring(0, str.indexOf('+'));
  }
  const frequency = getState().input.frequency;
  const frequencyPOST = removePlus(frequency);
  const period = getState().input.period;
  const isRestrictions = getState().input.isRestrictions;
  const period2POST = isRestrictions === 1 ? 999 : period;
  const isRestrictionsMediaBudget = getState().input.isRestrictionsMediaBudget;

  const campaignType = getState().input.campaignType;
  const campaignTypePOST = campaignType.find(type => type.isChecked) && campaignType.find(type => type.isChecked).name;
  const media = getState().input.media;
  const mediaPOST = media.map(media => {
    return { media: media.media, CPP: media.CPP * media.k, coeff: +media.coeff / 100, group: media.group, startBudget: media.startBudget, finishBudget: media.finishBudget, isChecked: media.isChecked };
  });
  const mediaPOSTForCharts = media.map(media => {
    return { media: media.media, CPP: media.CPP, coeff: +media.coeff / 100, group: media.group, startBudget: media.startBudget, finishBudget: media.finishBudget, isChecked: media.isChecked, k: media.k };
  });
  const geo = getState().input.geo;
  const geoPOST = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).name;
  const targetAudience = getState().input.targetAudience;
  const targetAudience_NSPK = findTAWithOldNames(targetAudience);
  const budget_coverage = getState().input.budget_coverage;
  const budget_coveragePOST = budget_coverage.find(item => item.isChecked) && +budget_coverage.find(item => item.isChecked).value;
  const budget_coverageName = budget_coverage.find(item => item.isChecked) && budget_coverage.find(item => item.isChecked).name;
  const budget = budget_coverage.find(item => item.name === 'budget');
  const BUDGET_FOR_NEXT_CHARTS = getState().input.BUDGET_FOR_NEXT_CHARTS;
  const REACH_FOR_NEXT_CHARTS = getState().input.REACH_FOR_NEXT_CHARTS;
  const month = getState().input.month;
  const isMonthVisible = getState().input.isMonthVisible;

  try {
    dispatch(setStatus('loading'));
    const response = budget.isChecked ? await API2.post_chartDataPath1_budget(JSON.stringify({ isRestrictionsMediaBudget: isRestrictionsMediaBudget, frequency: frequencyPOST, period: period, period2: period2POST, geo: geoPOST, targetAudience: targetAudience_NSPK, campaignType: campaignTypePOST, Budget: budget_coveragePOST, media: mediaPOST, project: 'mediamix' })) : await API2.post_chartDataPath1_coverage(JSON.stringify({ isRestrictionsMediaBudget: isRestrictionsMediaBudget, frequency: frequencyPOST, period: period, period2: period2POST, geo: geoPOST, targetAudience: targetAudience_NSPK, campaignType: campaignTypePOST, Reach: budget_coveragePOST, media: mediaPOST, project: 'mediamix' }));

    if (response.statusText === 'OK') {
      const allMediaName = media.map(item => item.media);
      const allColorsArray = allMediaName.map((key, i) => {
        return {
          [key]: colorsArr[i],
        };
      });
      const allMediaGroup = makeArrayGroupNames(media.map(item => item.group));
      const allColorsGroupArray = allMediaGroup.map((key, i) => {
        return {
          [key]: colorsGroupArr[i],
        };
      });
      dispatch(setAllChartsData(response));

      const BUDGET = JSON.parse(response.data[0].Budget);
      const knowledgeGeneral = response.data[1];
      const reachGeneral = response.data[0].Media_mix_compare[4][0];
      const donutMedia = Object.keys(BUDGET);
      const donutBudget = makeArrayBudget(BUDGET);

      const donutBudgetPercent = makeArrayBudgetPr(BUDGET);
      const donutMediaGroup = makeArrayGroup(BUDGET);
      const donutBudgetGroup = makeArrayBudgetGroup(BUDGET);
      const donutBudgetPercentGroup = makeArrayBudgetGroupPr(BUDGET);
      const chooseLabel = () => {
        if (grouping === 'all') {
          if (unit === '%') return donutBudgetPercent;
          else if (unit === '₽') return donutBudget;
        } else if (grouping === 'group') {
          if (unit === '%') return donutBudgetPercentGroup;
          else if (unit === '₽') return donutBudgetGroup;
        }
      };
      const donutAllColorsArray = donutMedia.map((key, i) => {
        return {
          [key]: Object.values(findName(key, allColorsArray))[0],
        };
      });
      const INCREACHMEDIA = Object.keys(response.data[0].Incremental_reach);
      const tv = INCREACHMEDIA.find(media => media === 'TV');
      const mainMediaByBudget = findMainMediaByBudget(BUDGET, donutBudget);
      const firstMainMedia = tv ? tv : mainMediaByBudget;
      dispatch(setMainMedia(firstMainMedia));

      dispatch(setTs_pieChart_unit_checked(true));
      dispatch(setTs_pieChart_grouping_checked(true));
      dispatch(setTs_contribution_chart_unit_checked(true));
      dispatch(setTs_contribution_chart_grouping_checked(true));
      dispatch(setTs_optic_media_mix_chart_unit_checked(true));
      dispatch(setTs_optic_media_mix_chart_grouping_checked(true));
      dispatch(setUnitDonut('₽'));
      dispatch(setUnitOptic('₽'));
      dispatch(setIsChartsUpload(false));
      dispatch(setIsGrowthChartRendered(false));
      dispatch(set_investment_growth_graph_data({}));
      dispatch(setIsCoverageChartRendered(false));
      dispatch(set_investment_coverage_graph_data({}));

      dispatch(setPostData({ isRestrictionsMediaBudget: isRestrictionsMediaBudget, frequency: frequency, period: period, geo: geoPOST, targetAudience: targetAudience, campaignType: campaignTypePOST, budget_coverage: budget_coveragePOST, budget_coverageNAME: budget_coverageName, media: mediaPOSTForCharts, budgetForGrowthChart: BUDGET_FOR_NEXT_CHARTS, reachForCoverageChart: REACH_FOR_NEXT_CHARTS, Aud_totalCurrentValue: Aud_totalCurrentValue, month: month, isMonthVisible: isMonthVisible, isRestrictions: isRestrictions, knowledgeGeneral: knowledgeGeneral, reachGeneral: reachGeneral }));

      const INCREACH = JSON.parse(response.data[0].Incremental_reach[firstMainMedia]);
      const contributionMedia = Object.keys(INCREACH);
      const contributionBudget = makeArrayBudget(INCREACH);
      const MEDIA_MIX_COMPARE = response.data[0].Media_mix_compare;
      const budget = `${round(setMillion(makeSumBudget(JSON.parse(MEDIA_MIX_COMPARE[4][1]))))} млн`;

      const obg = JSON.parse(MEDIA_MIX_COMPARE[1][1]);
      const keys = Object.keys(obg);

      let coverages = [];
      dispatch(setColorsArray(allColorsArray));
      dispatch(setColorsGroupArray(allColorsGroupArray));

      const mediaArrayOptic = keys.map((key, index) => {
        return {
          [key]: [],
          name: key,
          color: Object.values(findName(key, allColorsArray))[0],
        };
      });

      for (const data of response.data[0].Media_mix_compare) {
        coverages.push(data[0]);
        for (let i = 0; i < keys.length; i++) {
          const findNameMedia = findName(keys[i], mediaArrayOptic);
          const DATA = JSON.parse(data[1]);
          Object.values(findNameMedia)[0].push(DATA[keys[i]].Budget);
        }
      }
      dispatch(setBUDGET_FOR_NEXT_CHARTS(response.data[0].Budget));
      const contributionAllColorsArray = contributionMedia.map((key, i) => {
        return {
          [key]: Object.values(findName(key, allColorsArray))[0],
        };
      });
      dispatch(
        setContributionOfEachMedia_data({
          labels: contributionMedia,

          datasets: [
            {
              label: 'Contribution',
              data: contributionBudget,
              backgroundColor: contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]),
              hoverOffset: 4,
            },
          ],
        })
      );
      dispatch(
        setOpticMediaMixGraphData({
          labels: [budget, budget, budget, budget, budget, budget, budget, budget, budget],
          datasets: [
            {
              type: 'line',
              tooltip: {
                callbacks: {
                  label: function (TooltipItem) {
                    return TooltipItem.dataset.label + ` ${frequency}: ` + round(TooltipItem.parsed.y, 2) + ` %`;
                  },
                },
              },
              label: 'Охват',
              borderColor: 'black',
              backgroundColor: 'black',
              borderWidth: 2,
              fill: false,
              data: coverages,
              yAxisID: 'ohvat',
              y2axis: true,

              datalabels: {
                display: false,
              },
            },
            ...mediaArrayOptic.map(array => ({
              type: 'bar',
              tooltip: {
                callbacks: {
                  label: function (TooltipItem) {
                    const changedTooltipItem = unitOptic === '₽' ? round(setMillion(TooltipItem.parsed.y), 1) : round(TooltipItem.parsed.y, 1);
                    return unitOptic === '₽' ? TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн' : TooltipItem.dataset.label + ': ' + changedTooltipItem + ` ${unitOptic}`;
                  },
                },
              },
              label: array[Object.keys(array)[1]],
              backgroundColor: array[Object.keys(array)[2]],
              data: array[Object.keys(array)[0]],
            })),
          ],
        })
      );
      dispatch(
        setDonutData({
          labels: grouping === 'all' ? donutMedia : donutMediaGroup,

          datasets: [
            {
              label: grouping === 'all' ? 'Pie' : 'PieGroup',
              data: chooseLabel(),
              backgroundColor: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
              hoverOffset: 4,
            },
          ],
        })
      );
    }
    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const postGrowth = () => async (dispatch, getState) => {
  const unitGrowth = getState().charts2.unitGrowth;
  // const Aud_totalCurrentValue = getState().input.Aud_totalCurrentValue;
  function removePlus(str) {
    return +str.substring(0, str.indexOf('+'));
  }
  const frequency = getState().input.frequency;
  const frequencyPOST = removePlus(frequency);
  const period = getState().input.period;
  const isRestrictions = getState().input.isRestrictions;
  const period2POST = isRestrictions === 1 ? 999 : period;
  const isRestrictionsMediaBudget = getState().input.isRestrictionsMediaBudget;

  const campaignType = getState().input.campaignType;
  const campaignTypePOST = campaignType.find(type => type.isChecked) && campaignType.find(type => type.isChecked).name;
  const media = getState().input.media;
  const mediaPOST = media.map(media => {
    return { media: media.media, CPP: media.CPP * media.k, coeff: +media.coeff / 100, group: media.group, startBudget: media.startBudget, finishBudget: media.finishBudget, isChecked: media.isChecked };
  });

  const geo = getState().input.geo;
  const geoPOST = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).name;
  const targetAudience = getState().input.targetAudience;
  const targetAudience_NSPK = findTAWithOldNames(targetAudience);
  const BUDGET_FOR_NEXT_CHARTS = getState().input.BUDGET_FOR_NEXT_CHARTS;
  try {
    dispatch(setStatus('loading'));

    const response1 = await API2.post_chartDataPath2_growthGraph(JSON.stringify({ isRestrictionsMediaBudget: isRestrictionsMediaBudget, frequency: frequencyPOST, period: period, period2: period2POST, geo: geoPOST, targetAudience: targetAudience_NSPK, campaignType: campaignTypePOST, Budget: BUDGET_FOR_NEXT_CHARTS, media: mediaPOST, project: 'mediamix' }));

    if (response1.statusText === 'OK') {
      dispatch(setAllChartsDataGrowth(response1));
      const GROWTH = response1.data[0];

      const obg = JSON.parse(GROWTH[0][1]);
      const keys = Object.keys(obg);
      const coveragesGrowth = [];
      const labelsArr = [];
      const colorsArray = getState().charts.colorsArray;
      const mediaArrayGrowth = keys.map((key, index) => {
        return {
          [key]: [],
          name: key,
          color: Object.values(findName(key, colorsArray))[0],
        };
      });

      for (const data of GROWTH) {
        const DATA = JSON.parse(data[1]);
        const sum = findSum(DATA);
        labelsArr.push(sum);
        coveragesGrowth.push(data[0]);
        for (let i = 0; i < keys.length; i++) {
          const findNameMedia = findName(keys[i], mediaArrayGrowth);
          Object.values(findNameMedia)[0].push(DATA[keys[i]].Budget);
        }
      }
      dispatch(setTs_investment_growth_chart_unit_checked(true));
      dispatch(setTs_investment_growth_chart_grouping_checked(true));
      dispatch(setUnitGrowth('₽'));
      dispatch(setIsChartsUpload(false));
      dispatch(setKnowledgeArr(response1.data[1]));
      dispatch(
        set_investment_growth_graph_data({
          labels: labelsArr.map(item => `${round(setMillion(item))} млн`),
          datasets: [
            {
              type: 'scatter',
              tooltip: {
                callbacks: {
                  label: function (TooltipItem) {
                    return TooltipItem.dataset.label + ` ${frequency}: ` + round(TooltipItem.parsed.y, 1) + ` %`;
                  },
                },
              },
              label: 'Охват',
              backgroundColor: 'black',
              fill: false,
              data: coveragesGrowth,
              yAxisID: 'ohvat',
              y2axis: true,
              datalabels: {
                display: false,
              },
            },
            ...mediaArrayGrowth.map(array => ({
              type: 'bar',
              tooltip: {
                callbacks: {
                  label: function (TooltipItem) {
                    const changedTooltipItem = unitGrowth === '₽' ? round(setMillion(TooltipItem.parsed.y), 1) : round(TooltipItem.parsed.y, 1);
                    return unitGrowth === '₽' ? TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн' : TooltipItem.dataset.label + ': ' + changedTooltipItem + ` ${unitGrowth}`;
                  },
                },
              },
              label: array[Object.keys(array)[1]],
              backgroundColor: array[Object.keys(array)[2]],
              data: array[Object.keys(array)[0]],
            })),
          ],
        })
      );
    }
    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const postCoverage = () => async (dispatch, getState) => {
  function removePlus(str) {
    return +str.substring(0, str.indexOf('+'));
  }
  // const Aud_totalCurrentValue = getState().input.Aud_totalCurrentValue;
  const frequency = getState().input.frequency;
  const frequencyPOST = removePlus(frequency);
  const period = getState().input.period;
  const isRestrictions = getState().input.isRestrictions;
  const period2POST = isRestrictions === 1 ? 999 : period;
  const isRestrictionsMediaBudget = getState().input.isRestrictionsMediaBudget;

  const campaignType = getState().input.campaignType;
  const campaignTypePOST = campaignType.find(type => type.isChecked) && campaignType.find(type => type.isChecked).name;
  const media = getState().input.media;
  const mediaPOST = media.map(media => {
    return { media: media.media, CPP: media.CPP * media.k, coeff: +media.coeff / 100, group: media.group, startBudget: media.startBudget, finishBudget: media.finishBudget, isChecked: media.isChecked };
  });
  const geo = getState().input.geo;
  const geoPOST = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).name;
  const targetAudience = getState().input.targetAudience;
  const targetAudience_NSPK = findTAWithOldNames(targetAudience);
  const BUDGET_FOR_NEXT_CHARTS = getState().input.BUDGET_FOR_NEXT_CHARTS;

  try {
    dispatch(setStatus('loading'));

    const response2 = await API2.post_chartDataPath2_coverageGraph(JSON.stringify({ isRestrictionsMediaBudget: isRestrictionsMediaBudget, frequency: frequencyPOST, period: period, period2: period2POST, geo: geoPOST, targetAudience: targetAudience_NSPK, campaignType: campaignTypePOST, Budget: BUDGET_FOR_NEXT_CHARTS, media: mediaPOST, project: 'mediamix' }));

    if (response2.statusText === 'OK') {
      dispatch(setAllChartsDataCoverage(response2));
      const COVERAGE = response2.data;
      const obgCoverage = JSON.parse(COVERAGE[0][1]);
      const keysCoverage = Object.keys(obgCoverage);
      const labelsArrCoverage = [];
      const colorsArray = getState().charts.colorsArray;

      const mediaArrayCoverage = keysCoverage.map((key, index) => {
        return {
          [key]: [],
          name: key,
          color: Object.values(findName(key, colorsArray))[0],
        };
      });

      for (const data of COVERAGE) {
        const DATA = JSON.parse(data[1]);
        labelsArrCoverage.push(data[0]);
        for (let i = 0; i < keysCoverage.length; i++) {
          const findNameMedia = findName(keysCoverage[i], mediaArrayCoverage);
          Object.values(findNameMedia)[0].push(DATA[keysCoverage[i]].Budget);
        }
      }
      dispatch(setTs_investment_coverage_chart_unit_checked(true));
      dispatch(setTs_investment_coverage_chart_grouping_checked(true));
      dispatch(setUnitCoverage('₽'));
      dispatch(setIsChartsUpload(false));

      dispatch(
        set_investment_coverage_graph_data({
          labels: labelsArrCoverage.map(item => `Охват ${round(item)} %`),
          datasets: mediaArrayCoverage.map(array => ({
            type: 'bar',
            label: array[Object.keys(array)[1]],
            backgroundColor: array[Object.keys(array)[2]],
            data: array[Object.keys(array)[0]],
          })),
        })
      );
    }
    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
