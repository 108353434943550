import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { inputParameters_reducer } from './inputParameters_reducer';
import { charts_reducer } from './charts_reducer';
import { app_reducer } from './app_reducer';
import { charts_part2_reducer } from './charts_part2_reducer';
import { flights_reducer } from './flights_reducer';
import { flights_charts_reducer } from './flights_charts_reducer';

const rootReducer = combineReducers({
  input: inputParameters_reducer,
  charts: charts_reducer,
  charts2: charts_part2_reducer,
  app: app_reducer,
  flights: flights_reducer,
  flights_charts: flights_charts_reducer,
});
const input = sessionStorage.getItem('input')
  ? JSON.parse(sessionStorage.getItem('input'))
  : {
      targetAudienceData: [],
      targetAudience: '',
      geo: [
        { id: 1, name: 'NAT', isChecked: false, geo: 'NAT' },
        { id: 2, name: 'Moscow', isChecked: false, geo: 'Moscow' },
        { id: 3, name: 'Kazan', isChecked: false, geo: 'Kazan' },
      ],
      budget_coverage: [
        { id: 1, name: 'budget', value: 0, isChecked: false },
        { id: 2, name: 'coverage', value: 0, isChecked: false },
        { id: 3, name: 'different_budget', value: 0, isChecked: false },
      ],
      frequency: '',
      period: 30,
      isRestrictions: 1,
      campaignType: [
        { id: 1, name: 'Промо', isChecked: false },
        { id: 2, name: 'Имидж', isChecked: false },
        { id: 3, name: '...', isChecked: false },
      ],
      media: [],
      copySelectedMedia: [],
      mediaIsSelected: false,
      allMediaFromBack: [],
      groupCheckedArr: [],
      BUDGET_FOR_NEXT_CHARTS: '',
      inputDataChanged: false,
      postData: {},
      Aud_total: [],
      Aud_totalCurrentValue: null,
      month: 'Июнь',
      isMonthVisible: 1,
      isRestrictionsMediaBudget: 1,
    };

const charts = sessionStorage.getItem('charts')
  ? JSON.parse(sessionStorage.getItem('charts'))
  : {
      chartsData: [],
      contributionOfEachMedia_data: {},
      optic_media_mix_graph_data: {},
      donutData: {},
      mainMedia: null,
      unitDonut: '₽',
      unitOptic: '₽',
      ts_pieChart_grouping_checked: true,
      ts_pieChart_unit_checked: true,
      ts_contribution_chart_grouping_checked: true,
      ts_contribution_chart_unit_checked: true,
      ts_optic_media_mix_chart_grouping_checked: true,
      ts_optic_media_mix_chart_unit_checked: true,
      dataFromXL: [],
      isChartsUpload: false,
    };

const charts2 = sessionStorage.getItem('charts2')
  ? JSON.parse(sessionStorage.getItem('charts2'))
  : {
      allChartsDataGrowth: [],
      allChartsDataCoverage: [],
      investment_coverage_graph_data: {},
      investment_growth_graph_data: {},
      unitGrowth: '₽',
      unitCoverage: '₽',
      ts_investment_growth_chart_grouping_checked: true,
      ts_investment_growth_chart_unit_checked: true,
      ts_investment_coverage_chart_grouping_checked: true,
      ts_investment_coverage_chart_unit_checked: true,
      isGrowthChartRendered: false,
      isCoverageChartRendered: false,
      knowledgeArr: [],
    };
const flights = sessionStorage.getItem('flights')
  ? JSON.parse(sessionStorage.getItem('flights'))
  : {
      Aud_totalCurrentValueFlightsArr: [],
      flightsMonth: 'Июнь',
      flightArr: [{ flight: 1, id: 3333 }],
      targetAudienceFlights: '',
      flightsCampaignTypesArr: [
        {
          flight: 3333,
          campaignType: [
            { id: 1, name: 'Промо', isChecked: false },
            { id: 2, name: 'Имидж', isChecked: false },
          ],
        },
      ],
      flightsGeo: [
        { id: 1, name: 'NAT', isChecked: false, geo: 'NAT' },
        { id: 2, name: 'Moscow', isChecked: false, geo: 'Moscow' },
        { id: 3, name: 'Kazan', isChecked: false, geo: 'Kazan' },
      ],
      flightsMediaArr: [
        {
          flight: 3333,
          mediaIsSelected: false,
          groupCheckedArr: [],
          media: [],
          copyStartMedia: [],
          defaultMediaData: [],
        },
      ],
      flightsFrequencyArr: [
        {
          flight: 3333,
          frequency: '',
        },
      ],
      flightsBudget_CoverageArr: [
        {
          flight: 3333,
          budget_coverage: [
            { id: 1, name: 'budget', value: 0, isChecked: false },
            { id: 2, name: 'coverage', value: 0, isChecked: false },
            { id: 3, name: 'different_budget', value: 0, isChecked: false },
          ],
        },
      ],
      flightsPeriod: [
        {
          flight: 3333,
          period: 0,
          startDate: 0,
          finishDate: 0,
        },
      ],
      flightsIsRestrictions: 1,
      flightsPostData: [],
      flightsInputStep1DataChanged: false,
      flightsInputStep2DataChanged: false,
      CPPAllMonth: [],
      copyCPPAllMonth: [],
      flightsisRestrictionsMediaBudget: 1,
    };
const flights_charts = sessionStorage.getItem('flights_charts')
  ? JSON.parse(sessionStorage.getItem('flights_charts'))
  : {
      flightsChartsData: [],
      flightsUpload: false,
      dataFromXL_Flights: [],
    };
const persistedState = { input, charts, charts2, flights, flights_charts };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunkMiddleware)));

store.subscribe(() => {
  sessionStorage.setItem('input', JSON.stringify(store.getState().input));
  sessionStorage.setItem('charts', JSON.stringify(store.getState().charts));
  sessionStorage.setItem('charts2', JSON.stringify(store.getState().charts2));
  sessionStorage.setItem('flights', JSON.stringify(store.getState().flights));
  sessionStorage.setItem('flights_charts', JSON.stringify(store.getState().flights_charts));
});
