import React from 'react';
import { useSelector } from 'react-redux';
import ReactExport from 'react-export-excel';
import { StepButton } from '../../components/Common/StepButton/StepButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const SaveXLSPart2 = props => {
  const { chartsData, allChartsDataGrowth, allChartsDataCoverage, mainMedia, postData, dataFromXL } = props;
  const isChartsUpload = useSelector(state => state.charts.isChartsUpload);
  const groupCheckedArr = useSelector(state => state.input.groupCheckedArr);
  const copySelectedMedia = useSelector(state => state.input.copySelectedMedia);

  const getDataForExportXLSPart2_DataFromXL = dataFromXL => {
    const dataSetDonut_dataFromXL = dataFromXL
      .filter(item => item.Media1)
      .map(item => ({
        name: item.Media1,
        budget: item.Budget,
        budgetPr: item.BudgetPr,
        group: item.Group,
      }));
    const donutMedia_DataFromXL = dataSetDonut_dataFromXL.map(item => item.name);

    const contributionData = dataFromXL.filter(item => item.Media2);
    const makeArrayMediaContributionName = arr => {
      let newArr = [];
      arr.map(item => {
        !newArr.find(arrItem => arrItem === item.MainMedia) && newArr.push(item.MainMedia);
        return item;
      });
      return newArr;
    };
    const mediaContribution = makeArrayMediaContributionName(contributionData);
    const DATASETCONTR_dataFromXL = [];
    for (var i = 0; i < donutMedia_DataFromXL.length; i++) {
      const arr = contributionData
        // eslint-disable-next-line no-loop-func
        .filter(item => item.MainMedia === mediaContribution[i])
        .map(item => ({
          mainMedia: item.MainMedia,
          name: item.Media2,
          group: item.Group,
          budget: item.Budget,
          budgetPr: item.BudgetPr,
        }));
      DATASETCONTR_dataFromXL.push(arr);
    }
    const dataSetOptic_dataFromXL = dataFromXL
      .filter(item => item.Media3)
      .map(item => ({
        name: item.Media3,
        group: item.Group,
        option1: item.Option1,
        option1Pr: item.Option1Pr,
        option2: item.Option2,
        option2Pr: item.Option2Pr,
        option3: item.Option3,
        option3Pr: item.Option3Pr,
        option4: item.Option4,
        option4Pr: item.Option4Pr,
        option5: item.Option5,
        option5Pr: item.Option5Pr,
        option6: item.Option6,
        option6Pr: item.Option6Pr,
        option7: item.Option7,
        option7Pr: item.Option7Pr,
        option8: item.Option8,
        option8Pr: item.Option8Pr,
        option9: item.Option9,
        option9Pr: item.Option9Pr,
      }));
    const dataSetReachOptic_dataFromXL = dataFromXL
      .filter(item => item.Reach)
      .map(item => ({
        name: item.Reach,
        option1: item.Option1,
        option2: item.Option2,
        option3: item.Option3,
        option4: item.Option4,
        option5: item.Option5,
        option6: item.Option6,
        option7: item.Option7,
        option8: item.Option8,
        option9: item.Option9,
      }));
    const dataSetTA_dataFromXL = dataFromXL.filter(item => item.targetAudience);
    const dataSetMedia_dataFromXL = dataFromXL.filter(item => item.media);

    const dataSetGrowth_dataFromXL = dataFromXL
      .filter(item => item.Media4)
      .map(item => ({
        name: item.Media4,
        group: item.Group,
        '40_mln': item['1'],
        '40_mln_pr': item['1_Pr'],
        '80_mln': item['2'],
        '80_mln_pr': item['2_Pr'],
        '120_mln': item['3'],
        '120_mln_pr': item['3_Pr'],
        '160_mln': item['4'],
        '160_mln_pr': item['4_Pr'],
        '200_mln': item['5'],
        '200_mln_pr': item['5_Pr'],
        '240_mln': item['6'],
        '240_mln_pr': item['6_Pr'],
        '280_mln': item['7'],
        '280_mln_pr': item['7_Pr'],
        '320_mln': item['8'],
        '320_mln_pr': item['8_Pr'],
        '360_mln': item['9'],
        '360_mln_pr': item['9_Pr'],
      }));
    const dataSetCoverage_dataFromXL = dataFromXL
      .filter(item => item.Media5)
      .map(item => ({
        name: item.Media5,
        group: item.Group,
        'coverage50%': item['1'],
        'coverage50%_pr': item['1_Pr'],
        'coverage55%': item['2'],
        'coverage55%_pr': item['2_Pr'],
        'coverage60%': item['3'],
        'coverage60%_pr': item['3_Pr'],
        'coverage65%': item['4'],
        'coverage65%_pr': item['4_Pr'],
        'coverage70%': item['5'],
        'coverage70%_pr': item['5_Pr'],
        'coverage75%': item['6'],
        'coverage75%_pr': item['6_Pr'],
        'coverage80%': item['7'],
        'coverage80%_pr': item['7_Pr'],
        'coverage85%': item['8'],
        'coverage85%_pr': item['8_Pr'],
        'coverage90%': item['9'],
        'coverage90%_pr': item['9_Pr'],
      }));
    const dataSetReachCoverage_dataFromXL = dataFromXL
      .filter(item => item.ReachCovarage)
      .map(item => ({
        name: item.ReachCovarage,
        '40_mln': item['1'],
        '80_mln': item['2'],
        '120_mln': item['3'],
        '160_mln': item['4'],
        '200_mln': item['5'],
        '240_mln': item['6'],
        '280_mln': item['7'],
        '320_mln': item['8'],
        '360_mln': item['9'],
      }));
    const dataSetReachGrowth_dataFromXL = dataFromXL
      .filter(item => item.ReachGrowth)
      .map(item => ({
        name: item.ReachGrowth,
        '40_mln': item['1'],
        '80_mln': item['2'],
        '120_mln': item['3'],
        '160_mln': item['4'],
        '200_mln': item['5'],
        '240_mln': item['6'],
        '280_mln': item['7'],
        '320_mln': item['8'],
        '360_mln': item['9'],
      }));
    const dataSetKnowledgeGrowth_dataFromXL = dataFromXL
      .filter(item => item.Knowledge)
      .map(item => ({
        Knowledge: item.Knowledge,
        1: item['1'],
        2: item['2'],
        3: item['3'],
        4: item['4'],
        5: item['5'],
        6: item['6'],
        7: item['7'],
        8: item['8'],
        9: item['9'],
      }));
    const dataSetGroupCheckedArr_dataFromXL = dataFromXL
      .filter(item => item.groupCheckbox)
      .map(item => ({
        name: item.groupCheckbox,
        isChecked: item.isChecked,
      }));
    const datasetMediaDefault_dataFromXL = dataFromXL
      .filter(item => item.mediaDefault)
      .map(item => ({
        media: item.mediaDefault,
        group: item.group,
        CPP: item.CPP,
        coeff: item.coeff,
        finishBudget: item.finishBudget,
        startBudget: item.startBudget,
        isChecked: item.isChecked,
        k: item.k,
      }));

    const dataset_Media_group_reach_DataFromXL = dataFromXL.filter(item => item.media_group_reach);
    const dataset_Media_reach_DataFromXL = dataFromXL.filter(item => item.media_reach);
    const dataset_Total_reach_DataFromXL = dataFromXL.filter(item => item.Total_reach);
    const dataset_Total_cprp_DataFromXL = dataFromXL.filter(item => item.Total_cprp);

    return [dataSetDonut_dataFromXL, DATASETCONTR_dataFromXL, dataSetOptic_dataFromXL, dataSetReachOptic_dataFromXL, dataSetTA_dataFromXL, dataSetMedia_dataFromXL, dataSetGrowth_dataFromXL, dataSetReachGrowth_dataFromXL, dataSetCoverage_dataFromXL, dataSetReachCoverage_dataFromXL, dataSetGroupCheckedArr_dataFromXL, datasetMediaDefault_dataFromXL, dataset_Media_group_reach_DataFromXL, dataset_Media_reach_DataFromXL, dataset_Total_reach_DataFromXL, dataset_Total_cprp_DataFromXL, dataSetKnowledgeGrowth_dataFromXL];
  };
  const dataSetDonut_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[0];
  const DATASETCONTR_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[1];
  const dataSetOptic_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[2];
  const dataSetReachOptic_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[3];
  const dataSetTA_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[4];
  const dataSetMedia_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[5];
  const dataSetGrowth_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[6];
  const dataSetReachGrowth_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[7];
  const dataSetCoverage_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[8];
  const dataSetReachCoverage_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[9];
  const dataSetGroupCheckedArr_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[10];
  const datasetMediaDefault_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[11];
  const dataset_Media_group_reach_DataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[12];
  const dataset_Media_reach_DataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[13];
  const dataset_Total_reach_DataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[14];
  const dataset_Total_cprp_DataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[15];
  const dataSetKnowledgeGrowth_dataFromXL = isChartsUpload && getDataForExportXLSPart2_DataFromXL(dataFromXL)[16];

  const getDataForExportXLSPart2_DataFromChartsData = (chartsData, allChartsDataGrowth, allChartsDataCoverage) => {
    if (Object.keys(chartsData).length === 0) {
      const dataSetDonut = dataFromXL
        .filter(item => item.Media1)
        .map(item => ({
          name: item.Media1,
          budget: item.Budget,
          budgetPr: item.BudgetPr,
          group: item.Group,
        }));
      const donutMedia_DataFromXL = dataSetDonut_dataFromXL.map(item => item.name);

      const contributionData = dataFromXL.filter(item => item.Media2);
      const makeArrayMediaContributionName = arr => {
        let newArr = [];
        arr.map(item => {
          !newArr.find(arrItem => arrItem === item.MainMedia) && newArr.push(item.MainMedia);
          return item;
        });
        return newArr;
      };
      const mediaContribution = makeArrayMediaContributionName(contributionData);

      const DATASETCONTR = [];
      for (var i = 0; i < donutMedia_DataFromXL.length; i++) {
        const arr = contributionData
          // eslint-disable-next-line no-loop-func
          .filter(item => item.MainMedia === mediaContribution[i])
          .map(item => ({
            mainMedia: item.MainMedia,
            name: item.Media2,
            group: item.Group,
            budget: item.Budget,
            budgetPr: item.BudgetPr,
          }));
        DATASETCONTR.push(arr);
      }
      const dataSetOptic = dataFromXL
        .filter(item => item.Media3)
        .map(item => ({
          name: item.Media3,
          group: item.Group,
          option1: item.Option1,
          option1Pr: item.Option1Pr,
          option2: item.Option2,
          option2Pr: item.Option2Pr,
          option3: item.Option3,
          option3Pr: item.Option3Pr,
          option4: item.Option4,
          option4Pr: item.Option4Pr,
          option5: item.Option5,
          option5Pr: item.Option5Pr,
          option6: item.Option6,
          option6Pr: item.Option6Pr,
          option7: item.Option7,
          option7Pr: item.Option7Pr,
          option8: item.Option8,
          option8Pr: item.Option8Pr,
          option9: item.Option9,
          option9Pr: item.Option9Pr,
        }));
      const dataSetReachOptic = dataFromXL
        .filter(item => item.Reach)
        .map(item => ({
          name: item.Reach,
          option1: item.Option1,
          option2: item.Option2,
          option3: item.Option3,
          option4: item.Option4,
          option5: item.Option5,
          option6: item.Option6,
          option7: item.Option7,
          option8: item.Option8,
          option9: item.Option9,
        }));
      const dataSetTA = dataFromXL.filter(item => item.targetAudience);
      const dataSetMedia = dataFromXL.filter(item => item.media);

      const GROWTH = allChartsDataGrowth.data[0];
      const KNOWLEDGE = allChartsDataGrowth.data[1];
      const obgGrowth = JSON.parse(GROWTH[0][1]);
      const GrowthMedia = Object.keys(obgGrowth);

      const COVERAGE = allChartsDataCoverage.data;
      const obgCoverage = JSON.parse(COVERAGE[0][1]);
      const coverageMedia = Object.keys(obgCoverage);
      const dataSetReachGrowth = [];
      const dataSetReachCoverage = [];
      const dataSetKnowledgeGrowth = [];

      const findGroupGrowth = media => {
        const findObj = GROWTH.find(item => Object.keys(JSON.parse(item[1])[media])[0]);
        return findObj && JSON.parse(findObj[1])[media].group;
      };
      const findGroupCoverage = media => {
        const findObj = COVERAGE.find(item => Object.keys(JSON.parse(item[1])[media])[0]);
        return findObj && JSON.parse(findObj[1])[media].group;
      };

      const dataSetGrowth = GrowthMedia.map((item, i) => {
        return {
          name: item,
          group: findGroupGrowth(item),
          '40_mln': JSON.parse(GROWTH[0][1])[item].Budget === null ? 0 : JSON.parse(GROWTH[0][1])[item].Budget,
          '80_mln': JSON.parse(GROWTH[1][1])[item].Budget === null ? 0 : JSON.parse(GROWTH[1][1])[item].Budget,
          '120_mln': JSON.parse(GROWTH[2][1])[item].Budget ? 0 : JSON.parse(GROWTH[2][1])[item].Budget,
          '160_mln': JSON.parse(GROWTH[3][1])[item].Budget ? 0 : JSON.parse(GROWTH[3][1])[item].Budget,
          '200_mln': JSON.parse(GROWTH[4][1])[item].Budget ? 0 : JSON.parse(GROWTH[4][1])[item].Budget,
          '240_mln': JSON.parse(GROWTH[5][1])[item].Budget ? 0 : JSON.parse(GROWTH[5][1])[item].Budget,
          '280_mln': JSON.parse(GROWTH[6][1])[item].Budget ? 0 : JSON.parse(GROWTH[6][1])[item].Budget,
          '320_mln': JSON.parse(GROWTH[7][1])[item].Budget ? 0 : JSON.parse(GROWTH[7][1])[item].Budget,
          '360_mln': JSON.parse(GROWTH[8][1])[item].Budget ? 0 : JSON.parse(GROWTH[8][1])[item].Budget,
          '40_mln_pr': +(JSON.parse(GROWTH[0][1])[item].BudgetPr * 100).toFixed(1),
          '80_mln_pr': +(JSON.parse(GROWTH[1][1])[item].BudgetPr * 100).toFixed(1),
          '120_mln_pr': +(JSON.parse(GROWTH[2][1])[item].BudgetPr * 100).toFixed(1),
          '160_mln_pr': +(JSON.parse(GROWTH[3][1])[item].BudgetPr * 100).toFixed(1),
          '200_mln_pr': +(JSON.parse(GROWTH[4][1])[item].BudgetPr * 100).toFixed(1),
          '240_mln_pr': +(JSON.parse(GROWTH[5][1])[item].BudgetPr * 100).toFixed(1),
          '280_mln_pr': +(JSON.parse(GROWTH[6][1])[item].BudgetPr * 100).toFixed(1),
          '320_mln_pr': +(JSON.parse(GROWTH[7][1])[item].BudgetPr * 100).toFixed(1),
          '360_mln_pr': +(JSON.parse(GROWTH[8][1])[item].BudgetPr * 100).toFixed(1),
        };
      });

      dataSetReachGrowth.push({
        name: 'reachGrowth',
        '40_mln': GROWTH[0][0],
        '80_mln': GROWTH[1][0],
        '120_mln': GROWTH[2][0],
        '160_mln': GROWTH[3][0],
        '200_mln': GROWTH[4][0],
        '240_mln': GROWTH[5][0],
        '280_mln': GROWTH[6][0],
        '320_mln': GROWTH[7][0],
        '360_mln': GROWTH[8][0],
      });
      dataSetKnowledgeGrowth.push({
        Knowledge: 'Knowledge',
        1: KNOWLEDGE[0],
        2: KNOWLEDGE[1],
        3: KNOWLEDGE[2],
        4: KNOWLEDGE[3],
        5: KNOWLEDGE[4],
        6: KNOWLEDGE[5],
        7: KNOWLEDGE[6],
        8: KNOWLEDGE[7],
        9: KNOWLEDGE[8],
      });

      const dataSetCoverage = coverageMedia.map((item, i) => {
        return {
          name: item,
          group: findGroupCoverage(item),
          'coverage50%': JSON.parse(COVERAGE[0][1])[item].Budget,
          'coverage55%': JSON.parse(COVERAGE[1][1])[item].Budget,
          'coverage60%': JSON.parse(COVERAGE[2][1])[item].Budget,
          'coverage65%': JSON.parse(COVERAGE[3][1])[item].Budget,
          'coverage70%': JSON.parse(COVERAGE[4][1])[item].Budget,
          'coverage75%': JSON.parse(COVERAGE[5][1])[item].Budget,
          'coverage80%': JSON.parse(COVERAGE[6][1])[item].Budget,
          'coverage85%': JSON.parse(COVERAGE[7][1])[item].Budget,
          'coverage90%': JSON.parse(COVERAGE[8][1])[item].Budget,
          'coverage50%_pr': +(JSON.parse(COVERAGE[0][1])[item].BudgetPr * 100).toFixed(1),
          'coverage55%_pr': +(JSON.parse(COVERAGE[1][1])[item].BudgetPr * 100).toFixed(1),
          'coverage60%_pr': +(JSON.parse(COVERAGE[2][1])[item].BudgetPr * 100).toFixed(1),
          'coverage65%_pr': +(JSON.parse(COVERAGE[3][1])[item].BudgetPr * 100).toFixed(1),
          'coverage70%_pr': +(JSON.parse(COVERAGE[4][1])[item].BudgetPr * 100).toFixed(1),
          'coverage75%_pr': +(JSON.parse(COVERAGE[5][1])[item].BudgetPr * 100).toFixed(1),
          'coverage80%_pr': +(JSON.parse(COVERAGE[6][1])[item].BudgetPr * 100).toFixed(1),
          'coverage85%_pr': +(JSON.parse(COVERAGE[7][1])[item].BudgetPr * 100).toFixed(1),
          'coverage90%_pr': +(JSON.parse(COVERAGE[8][1])[item].BudgetPr * 100).toFixed(1),
        };
      });

      dataSetReachCoverage.push({
        name: 'reachCoverage',
        '40_mln': COVERAGE[0][0],
        '80_mln': COVERAGE[1][0],
        '120_mln': COVERAGE[2][0],
        '160_mln': COVERAGE[3][0],
        '200_mln': COVERAGE[4][0],
        '240_mln': COVERAGE[5][0],
        '280_mln': COVERAGE[6][0],
        '320_mln': COVERAGE[7][0],
        '360_mln': COVERAGE[8][0],
      });

      const dataSetGroupCheckedArr = dataFromXL
        .filter(item => item.groupCheckbox)
        .map(item => ({
          name: item.groupCheckbox,
          isChecked: item.isChecked,
        }));
      const datasetMediaDefault = dataFromXL
        .filter(item => item.mediaDefault)
        .map(item => ({
          media: item.mediaDefault,
          group: item.group,
          CPP: item.CPP,
          coeff: item.coeff,
          finishBudget: item.finishBudget,
          startBudget: item.startBudget,
          isChecked: item.isChecked,
          k: item.k,
        }));
      const dataset_Media_group_reach = dataFromXL.filter(item => item.media_group_reach);
      const dataset_Media_reach = dataFromXL.filter(item => item.media_reach);
      const dataset_Total_reach = dataFromXL.filter(item => item.Total_reach);
      const dataset_Total_cprp = dataFromXL.filter(item => item.Total_cprp);

      return [dataSetDonut, DATASETCONTR, dataSetOptic, dataSetReachOptic, dataSetGrowth, dataSetReachGrowth, dataSetCoverage, dataSetReachCoverage, dataSetGroupCheckedArr, dataSetTA, dataSetMedia, datasetMediaDefault, dataset_Media_group_reach, dataset_Media_reach, dataset_Total_reach, dataset_Total_cprp, dataSetKnowledgeGrowth];
    } else {
      const BUDGET = JSON.parse(chartsData.data[0].Budget);
      const donutMedia = Object.keys(BUDGET);

      const INCREACH = JSON.parse(chartsData.data[0].Incremental_reach[mainMedia]);
      const contributionMedia = Object.keys(INCREACH);
      const DATASETCONTR = [];
      for (const key in chartsData.data[0].Incremental_reach) {
        const INCREACH_ITEM = JSON.parse(chartsData.data[0].Incremental_reach[key]);
        const dataSetContr = contributionMedia.map((item, i) => {
          return {
            mainMedia: key,
            name: item,
            group: BUDGET[item].group,
            budget: INCREACH_ITEM[item].Budget,
            budgetPr: INCREACH_ITEM[item].BudgetPr,
          };
        });
        DATASETCONTR.push(dataSetContr);
      }
      const MEDIA_MIX_COMPARE = chartsData.data[0].Media_mix_compare;
      const obg = JSON.parse(MEDIA_MIX_COMPARE[1][1]);
      const opticMedia = Object.keys(obg);

      const dataSetDonut = donutMedia.map((item, i) => {
        return {
          name: item,
          group: BUDGET[item].group,
          budget: BUDGET[item].Budget,
          budgetPr: BUDGET[item].BudgetPr,
        };
      });
      const dataSetReachOptic = [];
      const findGroup = media => {
        const findObj = MEDIA_MIX_COMPARE.find(item => JSON.parse(item[1])[media].name === media);
        return JSON.parse(findObj[1])[media].group;
      };
      const dataSetOptic = opticMedia.map((item, i) => {
        return {
          name: item,
          group: findGroup(item),
          option1: JSON.parse(MEDIA_MIX_COMPARE[0][1])[item].Budget,
          option2: JSON.parse(MEDIA_MIX_COMPARE[1][1])[item].Budget,
          option3: JSON.parse(MEDIA_MIX_COMPARE[2][1])[item].Budget,
          option4: JSON.parse(MEDIA_MIX_COMPARE[3][1])[item].Budget,
          option5: JSON.parse(MEDIA_MIX_COMPARE[4][1])[item].Budget,
          option6: JSON.parse(MEDIA_MIX_COMPARE[5][1])[item].Budget,
          option7: JSON.parse(MEDIA_MIX_COMPARE[6][1])[item].Budget,
          option8: JSON.parse(MEDIA_MIX_COMPARE[7][1])[item].Budget,
          option9: JSON.parse(MEDIA_MIX_COMPARE[8][1])[item].Budget,
          option1Pr: +(JSON.parse(MEDIA_MIX_COMPARE[0][1])[item].BudgetPr * 100).toFixed(1),
          option2Pr: +(JSON.parse(MEDIA_MIX_COMPARE[1][1])[item].BudgetPr * 100).toFixed(1),
          option3Pr: +(JSON.parse(MEDIA_MIX_COMPARE[2][1])[item].BudgetPr * 100).toFixed(1),
          option4Pr: +(JSON.parse(MEDIA_MIX_COMPARE[4][1])[item].BudgetPr * 100).toFixed(1),
          option5Pr: +(JSON.parse(MEDIA_MIX_COMPARE[3][1])[item].BudgetPr * 100).toFixed(1),
          option6Pr: +(JSON.parse(MEDIA_MIX_COMPARE[5][1])[item].BudgetPr * 100).toFixed(1),
          option7Pr: +(JSON.parse(MEDIA_MIX_COMPARE[6][1])[item].BudgetPr * 100).toFixed(1),
          option8Pr: +(JSON.parse(MEDIA_MIX_COMPARE[7][1])[item].BudgetPr * 100).toFixed(1),
          option9Pr: +(JSON.parse(MEDIA_MIX_COMPARE[8][1])[item].BudgetPr * 100).toFixed(1),
        };
      });
      dataSetReachOptic.push({
        name: 'reach',
        option1: MEDIA_MIX_COMPARE[0][0],
        option2: MEDIA_MIX_COMPARE[1][0],
        option3: MEDIA_MIX_COMPARE[2][0],
        option4: MEDIA_MIX_COMPARE[3][0],
        option5: MEDIA_MIX_COMPARE[4][0],
        option6: MEDIA_MIX_COMPARE[5][0],
        option7: MEDIA_MIX_COMPARE[6][0],
        option8: MEDIA_MIX_COMPARE[7][0],
        option9: MEDIA_MIX_COMPARE[8][0],
      });

      const GROWTH = allChartsDataGrowth.data[0];
      const KNOWLEDGE = allChartsDataGrowth.data[1];
      const obgGrowth = JSON.parse(GROWTH[0][1]);
      const GrowthMedia = Object.keys(obgGrowth);

      const COVERAGE = allChartsDataCoverage.data;
      const obgCoverage = JSON.parse(COVERAGE[0][1]);
      const coverageMedia = Object.keys(obgCoverage);
      const dataSetReachGrowth = [];
      const dataSetReachCoverage = [];
      const dataSetKnowledgeGrowth = [];

      const findGroupGrowth = media => {
        const findObj = GROWTH.find(item => Object.keys(JSON.parse(item[1])[media])[0]);
        return findObj && JSON.parse(findObj[1])[media].group;
      };
      const findGroupCoverage = media => {
        const findObj = COVERAGE.find(item => Object.keys(JSON.parse(item[1])[media])[0]);
        return findObj && JSON.parse(findObj[1])[media].group;
      };

      const dataSetGrowth = GrowthMedia.map((item, i) => {
        return {
          name: item,
          group: findGroupGrowth(item),
          '40_mln': JSON.parse(GROWTH[0][1])[item].Budget === null ? 0 : JSON.parse(GROWTH[0][1])[item].Budget,
          '80_mln': JSON.parse(GROWTH[1][1])[item].Budget === null ? 0 : JSON.parse(GROWTH[1][1])[item].Budget,
          '120_mln': JSON.parse(GROWTH[2][1])[item].Budget === null ? 0 : JSON.parse(GROWTH[2][1])[item].Budget,
          '160_mln': JSON.parse(GROWTH[3][1])[item].Budget === null ? 0 : JSON.parse(GROWTH[3][1])[item].Budget,
          '200_mln': JSON.parse(GROWTH[4][1])[item].Budget === null ? 0 : JSON.parse(GROWTH[4][1])[item].Budget,
          '240_mln': JSON.parse(GROWTH[5][1])[item].Budget === null ? 0 : JSON.parse(GROWTH[5][1])[item].Budget,
          '280_mln': JSON.parse(GROWTH[6][1])[item].Budget === null ? 0 : JSON.parse(GROWTH[6][1])[item].Budget,
          '320_mln': JSON.parse(GROWTH[7][1])[item].Budget === null ? 0 : JSON.parse(GROWTH[7][1])[item].Budget,
          '360_mln': JSON.parse(GROWTH[8][1])[item].Budget === null ? 0 : JSON.parse(GROWTH[8][1])[item].Budget,
          '40_mln_pr': +(JSON.parse(GROWTH[0][1])[item].BudgetPr * 100).toFixed(1),
          '80_mln_pr': +(JSON.parse(GROWTH[1][1])[item].BudgetPr * 100).toFixed(1),
          '120_mln_pr': +(JSON.parse(GROWTH[2][1])[item].BudgetPr * 100).toFixed(1),
          '160_mln_pr': +(JSON.parse(GROWTH[3][1])[item].BudgetPr * 100).toFixed(1),
          '200_mln_pr': +(JSON.parse(GROWTH[4][1])[item].BudgetPr * 100).toFixed(1),
          '240_mln_pr': +(JSON.parse(GROWTH[5][1])[item].BudgetPr * 100).toFixed(1),
          '280_mln_pr': +(JSON.parse(GROWTH[6][1])[item].BudgetPr * 100).toFixed(1),
          '320_mln_pr': +(JSON.parse(GROWTH[7][1])[item].BudgetPr * 100).toFixed(1),
          '360_mln_pr': +(JSON.parse(GROWTH[8][1])[item].BudgetPr * 100).toFixed(1),
        };
      });

      dataSetReachGrowth.push({
        name: 'reachGrowth',
        '40_mln': GROWTH[0][0],
        '80_mln': GROWTH[1][0],
        '120_mln': GROWTH[2][0],
        '160_mln': GROWTH[3][0],
        '200_mln': GROWTH[4][0],
        '240_mln': GROWTH[5][0],
        '280_mln': GROWTH[6][0],
        '320_mln': GROWTH[7][0],
        '360_mln': GROWTH[8][0],
      });
      dataSetKnowledgeGrowth.push({
        Knowledge: 'Knowledge',
        1: KNOWLEDGE[0],
        2: KNOWLEDGE[1],
        3: KNOWLEDGE[2],
        4: KNOWLEDGE[3],
        5: KNOWLEDGE[4],
        6: KNOWLEDGE[5],
        7: KNOWLEDGE[6],
        8: KNOWLEDGE[7],
        9: KNOWLEDGE[8],
      });

      const dataSetCoverage = coverageMedia.map((item, i) => {
        return {
          name: item,
          group: findGroupCoverage(item),
          'coverage50%': JSON.parse(COVERAGE[0][1])[item].Budget,
          'coverage55%': JSON.parse(COVERAGE[1][1])[item].Budget,
          'coverage60%': JSON.parse(COVERAGE[2][1])[item].Budget,
          'coverage65%': JSON.parse(COVERAGE[3][1])[item].Budget,
          'coverage70%': JSON.parse(COVERAGE[4][1])[item].Budget,
          'coverage75%': JSON.parse(COVERAGE[5][1])[item].Budget,
          'coverage80%': JSON.parse(COVERAGE[6][1])[item].Budget,
          'coverage85%': JSON.parse(COVERAGE[7][1])[item].Budget,
          'coverage90%': JSON.parse(COVERAGE[8][1])[item].Budget,
          'coverage50%_pr': +(JSON.parse(COVERAGE[0][1])[item].BudgetPr * 100).toFixed(1),
          'coverage55%_pr': +(JSON.parse(COVERAGE[1][1])[item].BudgetPr * 100).toFixed(1),
          'coverage60%_pr': +(JSON.parse(COVERAGE[2][1])[item].BudgetPr * 100).toFixed(1),
          'coverage65%_pr': +(JSON.parse(COVERAGE[3][1])[item].BudgetPr * 100).toFixed(1),
          'coverage70%_pr': +(JSON.parse(COVERAGE[4][1])[item].BudgetPr * 100).toFixed(1),
          'coverage75%_pr': +(JSON.parse(COVERAGE[5][1])[item].BudgetPr * 100).toFixed(1),
          'coverage80%_pr': +(JSON.parse(COVERAGE[6][1])[item].BudgetPr * 100).toFixed(1),
          'coverage85%_pr': +(JSON.parse(COVERAGE[7][1])[item].BudgetPr * 100).toFixed(1),
          'coverage90%_pr': +(JSON.parse(COVERAGE[8][1])[item].BudgetPr * 100).toFixed(1),
        };
      });

      dataSetReachCoverage.push({
        name: 'reachCoverage',
        '40_mln': COVERAGE[0][0],
        '80_mln': COVERAGE[1][0],
        '120_mln': COVERAGE[2][0],
        '160_mln': COVERAGE[3][0],
        '200_mln': COVERAGE[4][0],
        '240_mln': COVERAGE[5][0],
        '280_mln': COVERAGE[6][0],
        '320_mln': COVERAGE[7][0],
        '360_mln': COVERAGE[8][0],
      });

      const dataSetGroupCheckedArr = groupCheckedArr.map(item => ({
        name: Object.keys(item)[0],
        isChecked: Object.values(item)[0],
      }));
      const dataSetTA = [];
      dataSetTA.push(postData);
      const dataSetMedia = postData.media;
      const datasetMediaDefault = copySelectedMedia;

      const Media_group_reach = chartsData.data[0].Media_group_reach;
      const MediaGroupArr = Object.keys(Media_group_reach);
      const dataset_Media_group_reach = MediaGroupArr.map(item => ({
        name: 'Media_group_reach',
        media_group_reach: item,
        '1+': Media_group_reach[item][0],
        '2+': Media_group_reach[item][1],
        '3+': Media_group_reach[item][2],
        '4+': Media_group_reach[item][3],
        '5+': Media_group_reach[item][4],
        '6+': Media_group_reach[item][5],
        '7+': Media_group_reach[item][6],
        '8+': Media_group_reach[item][7],
        '9+': Media_group_reach[item][8],
        '10+': Media_group_reach[item][9],
      }));
      const Media_reach = chartsData.data[0].Media_reach;
      const MediaArr = Object.keys(Media_reach);
      const dataset_Media_reach = MediaArr.map(item => ({
        name: 'Media_reach',
        media_reach: item,
        '1+': Media_reach[item][0],
        '2+': Media_reach[item][1],
        '3+': Media_reach[item][2],
        '4+': Media_reach[item][3],
        '5+': Media_reach[item][4],
        '6+': Media_reach[item][5],
        '7+': Media_reach[item][6],
        '8+': Media_reach[item][7],
        '9+': Media_reach[item][8],
        '10+': Media_reach[item][9],
      }));

      const Total_reach_cprp = chartsData.data[0].Total_reach_cprp;
      const dataset_Total_reach = [];
      dataset_Total_reach.push({
        Total_reach: 'Total_reach',
        '1+': Total_reach_cprp[0][0],
        '2+': Total_reach_cprp[0][1],
        '3+': Total_reach_cprp[0][2],
        '4+': Total_reach_cprp[0][3],
        '5+': Total_reach_cprp[0][4],
        '6+': Total_reach_cprp[0][5],
        '7+': Total_reach_cprp[0][6],
        '8+': Total_reach_cprp[0][7],
        '9+': Total_reach_cprp[0][8],
        '10+': Total_reach_cprp[0][9],
      });
      const dataset_Total_cprp = [];
      dataset_Total_cprp.push({
        Total_cprp: 'Total_cprp',
        '1+': Total_reach_cprp[1][0],
        '2+': Total_reach_cprp[1][1],
        '3+': Total_reach_cprp[1][2],
        '4+': Total_reach_cprp[1][3],
        '5+': Total_reach_cprp[1][4],
        '6+': Total_reach_cprp[1][5],
        '7+': Total_reach_cprp[1][6],
        '8+': Total_reach_cprp[1][7],
        '9+': Total_reach_cprp[1][8],
        '10+': Total_reach_cprp[1][9],
      });

      return [dataSetDonut, DATASETCONTR, dataSetOptic, dataSetReachOptic, dataSetGrowth, dataSetReachGrowth, dataSetCoverage, dataSetReachCoverage, dataSetGroupCheckedArr, dataSetTA, dataSetMedia, datasetMediaDefault, dataset_Media_group_reach, dataset_Media_reach, dataset_Total_reach, dataset_Total_cprp, dataSetKnowledgeGrowth];
    }
  };
  const dataSetDonut = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[0];
  const DATASETCONTR = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[1];
  const dataSetOptic = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[2];
  const dataSetReachOptic = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[3];
  const dataSetGrowth = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[4];
  const dataSetReachGrowth = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[5];
  const dataSetCoverage = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[6];
  const dataSetReachCoverage = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[7];
  const dataSetGroupCheckedArr = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[8];
  const dataSetTA = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[9];
  const dataSetMedia = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[10];
  const datasetMediaDefault = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[11];
  const dataset_Media_group_reach = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[12];
  const dataset_Media_reach = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[13];
  const dataset_Total_reach = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[14];
  const dataset_Total_cprp = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[15];
  const dataSetKnowledgeGrowth = !isChartsUpload && getDataForExportXLSPart2_DataFromChartsData(chartsData, allChartsDataGrowth, allChartsDataCoverage)[16];

  const chooseDATASETCONTR = () => {
    if (isChartsUpload) {
      return DATASETCONTR_dataFromXL;
    } else if (!isChartsUpload) {
      return DATASETCONTR;
    }
  };

  return (
    <ExcelFile
      element={
        <StepButton title={'Сохранить\n проект'}>
          <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
        </StepButton>
      }
      filename='save_media_mix_calculator_charts_part2'
    >
      <ExcelSheet data={isChartsUpload ? dataSetTA_dataFromXL : dataSetTA} name='Вводные данные'>
        <ExcelColumn label='targetAudience' value='targetAudience' />
        <ExcelColumn label='geo' value='geo' />
        <ExcelColumn label='period' value='period' />
        <ExcelColumn label='isRestrictions' value='isRestrictions' />
        <ExcelColumn label='campaignType' value='campaignType' />
        <ExcelColumn label='frequency' value='frequency' />
        <ExcelColumn label='budget_coverageNAME' value='budget_coverageNAME' />
        <ExcelColumn label='budget_coverage' value='budget_coverage' />
        <ExcelColumn label='Aud_totalCurrentValue' value='Aud_totalCurrentValue' />
        <ExcelColumn label='month' value='month' />
        <ExcelColumn label='isMonthVisible' value='isMonthVisible' />
        <ExcelColumn label='knowledgeGeneral' value='knowledgeGeneral' />
        <ExcelColumn label='reachGeneral' value='reachGeneral' />
        <ExcelColumn label='isRestrictionsMediaBudget' value='isRestrictionsMediaBudget' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataSetMedia_dataFromXL : dataSetMedia} name='Медиа'>
        <ExcelColumn label='media' value='media' />
        <ExcelColumn label='group' value='group' />
        <ExcelColumn label='CPP' value='CPP' />
        <ExcelColumn label='coeff' value='coeff' />
        <ExcelColumn label='startBudget' value='startBudget' />
        <ExcelColumn label='finishBudget' value='finishBudget' />
        <ExcelColumn label='isChecked' value='isChecked' />
        <ExcelColumn label='k' value='k' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataSetDonut_dataFromXL : dataSetDonut} name='Рекомендуемый медиа микс'>
        <ExcelColumn label='Media1' value='name' widthPx='100' />
        <ExcelColumn label='Budget' value='budget' />
        <ExcelColumn label='BudgetPr' value='budgetPr' />
        <ExcelColumn label='Group' value='group' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataSetOptic_dataFromXL : dataSetOptic} name='Сравнение различных медиамиксов'>
        <ExcelColumn label='Media3' value='name' />
        <ExcelColumn label='Group' value='group' />
        <ExcelColumn label='Option1' value='option1' />
        <ExcelColumn label='Option1Pr' value='option1Pr' />
        <ExcelColumn label='Option2' value='option2' />
        <ExcelColumn label='Option2Pr' value='option2Pr' />
        <ExcelColumn label='Option3' value='option3' />
        <ExcelColumn label='Option3Pr' value='option3Pr' />
        <ExcelColumn label='Option4' value='option4' />
        <ExcelColumn label='Option4Pr' value='option4Pr' />
        <ExcelColumn label='Option5' value='option5' />
        <ExcelColumn label='Option5Pr' value='option5Pr' />
        <ExcelColumn label='Option6' value='option6' />
        <ExcelColumn label='Option6Pr' value='option6Pr' />
        <ExcelColumn label='Option7' value='option7' />
        <ExcelColumn label='Option7Pr' value='option7Pr' />
        <ExcelColumn label='Option8' value='option8' />
        <ExcelColumn label='Option8Pr' value='option8Pr' />
        <ExcelColumn label='Option9' value='option9' />
        <ExcelColumn label='Option9Pr' value='option9Pr' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataSetReachOptic_dataFromXL : dataSetReachOptic} name='Охват_сравнение различных медиамиксов'>
        <ExcelColumn label='Reach' value='name' />
        <ExcelColumn label='Option1' value='option1' />
        <ExcelColumn label='Option2' value='option2' />
        <ExcelColumn label='Option3' value='option3' />
        <ExcelColumn label='Option4' value='option4' />
        <ExcelColumn label='Option5' value='option5' />
        <ExcelColumn label='Option6' value='option6' />
        <ExcelColumn label='Option7' value='option7' />
        <ExcelColumn label='Option8' value='option8' />
        <ExcelColumn label='Option9' value='option9' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataSetGrowth_dataFromXL : dataSetGrowth} name='Влияние прироста инвестиций в рекламу'>
        <ExcelColumn label='Media4' value='name' />
        <ExcelColumn label='Group' value='group' />
        <ExcelColumn label='1' value='40_mln' />
        <ExcelColumn label='1_Pr' value='40_mln_pr' />
        <ExcelColumn label='2' value='80_mln' />
        <ExcelColumn label='2_Pr' value='80_mln_pr' />
        <ExcelColumn label='3' value='120_mln' />
        <ExcelColumn label='3_Pr' value='120_mln_pr' />
        <ExcelColumn label='4' value='160_mln' />
        <ExcelColumn label='4_Pr' value='160_mln_pr' />
        <ExcelColumn label='5' value='200_mln' />
        <ExcelColumn label='5_Pr' value='200_mln_pr' />
        <ExcelColumn label='6' value='240_mln' />
        <ExcelColumn label='6_Pr' value='240_mln_pr' />
        <ExcelColumn label='7' value='280_mln' />
        <ExcelColumn label='7_Pr' value='280_mln_pr' />
        <ExcelColumn label='8' value='320_mln' />
        <ExcelColumn label='8_Pr' value='320_mln_pr' />
        <ExcelColumn label='9' value='360_mln' />
        <ExcelColumn label='9_Pr' value='360_mln_pr' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataSetReachGrowth_dataFromXL : dataSetReachGrowth} name='Охват_влияние прироста инвестиций в рекламу'>
        <ExcelColumn label='ReachGrowth' value='name' />
        <ExcelColumn label='1' value='40_mln' />
        <ExcelColumn label='2' value='80_mln' />
        <ExcelColumn label='3' value='120_mln' />
        <ExcelColumn label='4' value='160_mln' />
        <ExcelColumn label='5' value='200_mln' />
        <ExcelColumn label='6' value='240_mln' />
        <ExcelColumn label='7' value='280_mln' />
        <ExcelColumn label='8' value='320_mln' />
        <ExcelColumn label='9' value='360_mln' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataSetKnowledgeGrowth_dataFromXL : dataSetKnowledgeGrowth} name='Знания'>
        <ExcelColumn label='Knowledge' value='Knowledge' />
        <ExcelColumn label='1' value='1' />
        <ExcelColumn label='2' value='2' />
        <ExcelColumn label='3' value='3' />
        <ExcelColumn label='4' value='4' />
        <ExcelColumn label='5' value='5' />
        <ExcelColumn label='6' value='6' />
        <ExcelColumn label='7' value='7' />
        <ExcelColumn label='8' value='8' />
        <ExcelColumn label='9' value='9' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataSetCoverage_dataFromXL : dataSetCoverage} name='Рекомендуемый объем инвестиций и медиамикс'>
        <ExcelColumn label='Media5' value='name' />
        <ExcelColumn label='Group' value='group' />
        <ExcelColumn label='1' value='coverage50%' />
        <ExcelColumn label='1_Pr' value='coverage50%_pr' />
        <ExcelColumn label='2' value='coverage55%' />
        <ExcelColumn label='2_Pr' value='coverage55%_pr' />
        <ExcelColumn label='3' value='coverage60%' />
        <ExcelColumn label='3_Pr' value='coverage60%_pr' />
        <ExcelColumn label='4' value='coverage65%' />
        <ExcelColumn label='4_Pr' value='coverage65%_pr' />
        <ExcelColumn label='5' value='coverage70%' />
        <ExcelColumn label='5_Pr' value='coverage70%_pr' />
        <ExcelColumn label='6' value='coverage75%' />
        <ExcelColumn label='6_Pr' value='coverage75%_pr' />
        <ExcelColumn label='7' value='coverage80%' />
        <ExcelColumn label='7_Pr' value='coverage80%_pr' />
        <ExcelColumn label='8' value='coverage85%' />
        <ExcelColumn label='8_Pr' value='coverage85%_pr' />
        <ExcelColumn label='9' value='coverage90%' />
        <ExcelColumn label='9_Pr' value='coverage90%_pr' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataSetReachCoverage_dataFromXL : dataSetReachCoverage} name='Охват_pекомендуемый объем инвестиций и медиамикс'>
        <ExcelColumn label='ReachCovarage' value='name' />
        <ExcelColumn label='1' value='40_mln' />
        <ExcelColumn label='2' value='80_mln' />
        <ExcelColumn label='3' value='120_mln' />
        <ExcelColumn label='4' value='160_mln' />
        <ExcelColumn label='5' value='200_mln' />
        <ExcelColumn label='6' value='240_mln' />
        <ExcelColumn label='7' value='280_mln' />
        <ExcelColumn label='8' value='320_mln' />
        <ExcelColumn label='9' value='360_mln' />
      </ExcelSheet>
      {chooseDATASETCONTR().map((dataset, index) => {
        return (
          // eslint-disable-next-line no-useless-concat
          <ExcelSheet key={index} data={dataset} name={'Вклад каждого медиа' + ` (${dataset[0].mainMedia})`}>
            <ExcelColumn label='MainMedia' value='mainMedia' />
            <ExcelColumn label='Media2' value='name' />
            <ExcelColumn label='Budget' value='budget' />
            <ExcelColumn label='BudgetPr' value='budgetPr' />
            <ExcelColumn label='Group' value='group' />
          </ExcelSheet>
        );
      })}
      <ExcelSheet data={isChartsUpload ? dataSetGroupCheckedArr_dataFromXL : dataSetGroupCheckedArr} name='Group checkbox'>
        <ExcelColumn label='groupCheckbox' value='name' />
        <ExcelColumn label='isChecked' value='isChecked' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? datasetMediaDefault_dataFromXL : datasetMediaDefault} name='Media default'>
        <ExcelColumn label='mediaDefault' value='media' />
        <ExcelColumn label='group' value='group' />
        <ExcelColumn label='CPP' value='CPP' />
        <ExcelColumn label='coeff' value='coeff' />
        <ExcelColumn label='startBudget' value='startBudget' />
        <ExcelColumn label='finishBudget' value='finishBudget' />
        <ExcelColumn label='isChecked' value='isChecked' />
        <ExcelColumn label='k' value='k' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataset_Media_group_reach_DataFromXL : dataset_Media_group_reach} name='Media_group_reach'>
        <ExcelColumn label='media_group_reach' value='media_group_reach' />
        <ExcelColumn label='1+' value='1+' />
        <ExcelColumn label='2+' value='2+' />
        <ExcelColumn label='3+' value='3+' />
        <ExcelColumn label='4+' value='4+' />
        <ExcelColumn label='5+' value='5+' />
        <ExcelColumn label='6+' value='6+' />
        <ExcelColumn label='7+' value='7+' />
        <ExcelColumn label='8+' value='8+' />
        <ExcelColumn label='9+' value='9+' />
        <ExcelColumn label='10+' value='10+' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataset_Media_reach_DataFromXL : dataset_Media_reach} name='Media_reach'>
        <ExcelColumn label='media_reach' value='media_reach' />
        <ExcelColumn label='1+' value='1+' />
        <ExcelColumn label='2+' value='2+' />
        <ExcelColumn label='3+' value='3+' />
        <ExcelColumn label='4+' value='4+' />
        <ExcelColumn label='5+' value='5+' />
        <ExcelColumn label='6+' value='6+' />
        <ExcelColumn label='7+' value='7+' />
        <ExcelColumn label='8+' value='8+' />
        <ExcelColumn label='9+' value='9+' />
        <ExcelColumn label='10+' value='10+' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataset_Total_reach_DataFromXL : dataset_Total_reach} name='Total_reach'>
        <ExcelColumn label='Total_reach' value='Total_reach' />
        <ExcelColumn label='1+' value='1+' />
        <ExcelColumn label='2+' value='2+' />
        <ExcelColumn label='3+' value='3+' />
        <ExcelColumn label='4+' value='4+' />
        <ExcelColumn label='5+' value='5+' />
        <ExcelColumn label='6+' value='6+' />
        <ExcelColumn label='7+' value='7+' />
        <ExcelColumn label='8+' value='8+' />
        <ExcelColumn label='9+' value='9+' />
        <ExcelColumn label='10+' value='10+' />
      </ExcelSheet>
      <ExcelSheet data={isChartsUpload ? dataset_Total_cprp_DataFromXL : dataset_Total_cprp} name='Total_cprp'>
        <ExcelColumn label='Total_cprp' value='Total_cprp' />
        <ExcelColumn label='1+' value='1+' />
        <ExcelColumn label='2+' value='2+' />
        <ExcelColumn label='3+' value='3+' />
        <ExcelColumn label='4+' value='4+' />
        <ExcelColumn label='5+' value='5+' />
        <ExcelColumn label='6+' value='6+' />
        <ExcelColumn label='7+' value='7+' />
        <ExcelColumn label='8+' value='8+' />
        <ExcelColumn label='9+' value='9+' />
        <ExcelColumn label='10+' value='10+' />
      </ExcelSheet>
    </ExcelFile>
  );
};
